<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <template v-for="(item, index) in breadcrumbs">
          <el-breadcrumb-item :key="index" :to="item.to">{{ item.label }}</el-breadcrumb-item>
        </template>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container" ref="container" @scroll="computeFixed">
      <div id="printContainer" class="yt-content" style="height: auto">
        <div class="header">
          <el-avatar :size="72" :src="resumeDetail.avatar" />
          <div style="margin-left: 30px;width: calc(100% - 120px);">
            <div class="first-row">
              <h1 style="margin-right: 30px">{{ resumeDetail.userName }}</h1>
              <el-button type="primary" :loading="downloadLoading" @click="download">下载附件简历</el-button>
            </div>
            <el-row class="interval">
              <el-col :span="5" class="has-border">
                <YTIcon :href="'#icon-biangengxingbie'" />
                <p class="nowrap">{{ resumeDetail.gender }}</p>
              </el-col>
              <el-col :span="7" class="has-border">
                <YTIcon :href="'#icon-nianling'" />
                <p class="nowrap">{{ resumeDetail.age }}</p>
              </el-col>
              <el-col :span="12" style="display: flex">
                <YTIcon :href="'#icon-gongling'" />
                <p class="nowrap">{{ resumeDetail.experience }}</p>
              </el-col>
            </el-row>
            <el-row class="interval" style="margin-top:10px">
              <el-col :span="5" class="has-border">
                <YTIcon :href="'#icon-shouji1'" />
                <el-tooltip placement="bottom" effect="dark">
                  <p class="nowrap">{{ resumeDetail.phone }}</p>
                  <div slot="content">{{ resumeDetail.phone }}</div>
                </el-tooltip>
              </el-col>
              <el-col :span="7" class="has-border">
                <YTIcon :href="'#icon-youxiang'" />
                <el-tooltip placement="bottom" effect="dark">
                  <p class="nowrap">{{ resumeDetail.email }}</p>
                  <div slot="content">{{ resumeDetail.email }}</div>
                </el-tooltip>
              </el-col>
              <el-col
                :span="6"
                class="has-border"
                v-if="resumeDetail.resumeInfo.hasOwnProperty('BASIC_INFO') && resumeDetail.resumeInfo['BASIC_INFO']['GROUP_0']['NATIVE_PLACE'] !== ''"
              >
                <YTIcon :href="'#icon-icon-gerenxinxi-jiguan'" />
                <template v-if="resumeDetail.hasOwnProperty('nativePlace')">
                  <el-tooltip placement="bottom" effect="dark">
                    <p class="nowrap">{{ resumeDetail.nativePlace ? resumeDetail.nativePlace.replaceAll(',', '') : '' }}</p>
                    <div slot="content">{{ resumeDetail.nativePlace ? resumeDetail.nativePlace.replaceAll(',', '') : '' }}</div>
                  </el-tooltip>
                </template>
              </el-col>
              <el-col
                :span="6"
                style="display: flex"
                v-if="
                  resumeDetail.resumeInfo.hasOwnProperty('BASIC_INFO') &&
                    resumeDetail.resumeInfo['BASIC_INFO']['GROUP_0']['SALARY_MIN'] !== '' &&
                    resumeDetail.resumeInfo['BASIC_INFO']['GROUP_0']['SALARY_MAX'] !== ''
                "
              >
                <YTIcon :href="'#icon-xinzi_icon'" />
                <p class="nowrap">{{ resumeDetail.salary }}K</p>
              </el-col>
            </el-row>
          </div>
        </div>
        <el-divider />
        <div class="block">
          <p class="title">教育经历</p>
          <div class="subtitle" v-for="(item, index) in resumeDetail.resumeInfo['EDUCATION_BACKGROUND']" :key="index">
            <ul class="interval-list deep" style="margin-right: 20px">
              <li class="has-border">{{ item['SCHOOL_NAME'] }}</li>
              <li class="has-border">{{ item['MAJOR'] }}</li>
              <li>{{ educationGrace[item['EDUCATION']] }}</li>
            </ul>
            <span>{{ item['SCHOOL_TIME'] }}</span>
          </div>
        </div>
        <el-divider />
        <div class="block" style="padding-bottom: 0">
          <p class="title">工作经历</p>
          <template v-if="resumeDetail.resumeInfo['WORK_EXPERIENCE'] && Object.keys(resumeDetail.resumeInfo['WORK_EXPERIENCE']).length > 0">
            <div style="margin-bottom: 40px" v-for="(item, index) in resumeDetail.resumeInfo['WORK_EXPERIENCE']" :key="index">
              <div class="subtitle" style="margin-bottom: 12px">
                <p>{{ item['ENTERPRISE_NAME'] }}</p>
                <span>{{ item['ON_JOB_TIME'] }}</span>
              </div>
              <ul class="interval-list deep" style="margin-bottom: 12px">
                <li class="has-border">{{ item['DEPARTMENT'] }}</li>
                <li>{{ item['POST'] }}</li>
              </ul>
              <div class="text">工作描述：{{ item['JOB_CONTENT'] }}</div>
            </div>
          </template>
          <template v-else>
            <YTIcon :href="'#icon-zanwushuju'" class="empty"></YTIcon>
          </template>
        </div>
        <el-divider />
        <div class="block">
          <p class="title">资格证书</p>
          <template v-if="resumeDetail.resumeInfo['CERTIFICATE'] && Object.keys(resumeDetail.resumeInfo['CERTIFICATE']).length > 0">
            <div class="subtitle" v-for="(item, index) in resumeDetail.resumeInfo['CERTIFICATE']" :key="index">
              <p>{{ item['CERTIFICATE_NAME'] }}</p>
              <span> {{ item['AWARD_TIME'] }}</span>
            </div>
          </template>
          <template v-else>
            <YTIcon :href="'#icon-zanwushuju'" class="empty"></YTIcon>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jobApi from '@api/job'
import YTIcon from '@components/common/YTIcon'
import ExamInviteDialog from '@components/common/dialog/ExamInviteDialog'
import ConfirmDialog from '@/components/common/dialog/ConfirmDialog'
import print from 'vue-print-nb'
import ossApi from '@api/oss'
export default {
  name: 'StaffApplyDetail',
  components: { YTIcon, ExamInviteDialog, ConfirmDialog },
  directives: {
    print
  },
  data() {
    return {
      resumeDetail: {
        resumeInfo: {},
        applyStatus: null
      },
      educationGrace: ['大专', '本科', '研究生', '博士', '不限'],
      jobResumeId: '',
      sideScrollTop: 10,
      downloadLoading: false
    }
  },
  computed: {
    breadcrumbs() {
      const routeEnum = {
        jobResume: [
          { label: '职位管理', to: '/manage/recruitment/job' },
          { label: '查看详情', to: '/manage/recruitment/job/detail' }
        ],
        staffResume: [{ label: '人才管理', to: '/manage/recruitment/staff' }]
      }
      return routeEnum[this.$route.name]
    }
  },
  mounted() {
    this.jobResumeId = this.$handleParams('StaffApplyDetailData').id
    this.getResumeDetail()
  },
  methods: {
    getResumeDetail() {
      //查看简历信息
      jobApi.getResumeDetail(this.jobResumeId).then(res => {
        this.resumeDetail = res.res
      })
    },
    download() {
      //下载附件
      let payload = {
        jobResumeIds: [this.jobResumeId]
      }
      this.downloadLoading = true
      ossApi
        .downloadResumeFile(payload)
        .then(res => {
          if (res.type === 'application/json') {
            let reader = new FileReader()
            reader.readAsText(res, 'utf-8')
            reader.onload = e => {
              const res = JSON.parse(reader.result)
              this.$message.error(res.res)
            }
          } else {
            this.$downloadFlow(res, '简历', 'zip')
          }
        })
        .finally(() => {
          this.downloadLoading = false
        })
    },
    computeFixed() {
      //固定侧边栏
      let height = this.$refs.container.scrollTop
      this.sideScrollTop = height === 0 ? 10 : height + 10
    }
  }
}
</script>

<style lang="less" scoped>
.yt-main {
  padding-top: 0;
}
.yt-container {
  height: 100%;
  padding: 10px;
  position: relative;
  overflow-y: auto;
  .yt-content {
    width: 100%;
  }
}
.header {
  .flexStyle(flex, flex-start);
  margin-bottom: 20px;
  .first-row {
    .flexStyle(flex, space-between);
    margin-bottom: 16px;
    h1 {
      font-size: 20px;
    }
    li {
      height: 20px;
      padding: 0 12px;
      margin-right: 10px;
      color: #448bff;
      background: #edf4ff;
      border-radius: 10px;
    }
    .el-button {
      width: 140px;
      height: 32px;
      margin-right: 20px;
      padding: 6px 15px 6px 16px;
    }
  }
}
.interval {
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  .has-border {
    display: flex;
    position: relative;
    p {
      max-width: calc(100% - 60px);
    }
    &:after {
      content: '';
      width: 1px;
      height: 20px;
      background-color: #d3d3d3;
      position: absolute;
      right: 30px;
      top: 0;
      bottom: 0;
    }
  }
  svg {
    font-size: 19px;
    color: #d3d3d3;
  }
  p {
    margin-left: 5px;
    cursor: pointer;
  }
}
.interval-list {
  .flexStyle(flex, flex-start);
  color: #666666;
  font-size: @medium;
  line-height: 20px;
  &.deep {
    color: #333;
    font-weight: bold;
  }
  li {
    margin-right: 20px;
    &.has-border {
      position: relative;
      &:after {
        content: '';
        width: 1px;
        height: 14px;
        background-color: #d3d3d3;
        position: absolute;
        right: -10px;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }
  }
}
.el-divider {
  width: calc(100% + 40px);
  margin: 0 auto 0 -20px;
  background-color: #f0f0f0;
}
.block {
  padding: 20px 0;
  .title {
    line-height: 22px;
    margin-bottom: 20px;
    font-size: @default;
    font-weight: bold;
    position: relative;
    &:before {
      content: '';
      width: 4px;
      height: 20px;
      position: absolute;
      left: -20px;
      top: 0;
      background-color: #2878ff;
      border-radius: 1px;
    }
  }
  .subtitle {
    display: flex;
    color: #333;
    font-size: @medium;
    margin-top: 20px;
    p {
      font-weight: bold;
      margin-right: 20px;
    }
  }
  .skill-input {
    width: 60px;
    height: 20px;
    border-radius: 10px;
    color: #ffffff;
    text-align: center;
    font-size: 12px;
  }
  .text {
    color: #666;
    font-size: @medium;
    white-space: pre-wrap;
  }
}
.project-item {
  .text {
    margin-top: 12px;
  }
}
.empty {
  font-size: 100px;
  display: block;
  margin: 0 auto 10px;
}
</style>
