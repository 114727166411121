<template>
  <el-dialog
    :visible.sync="visible"
    title="邀请考试"
    class="yt-dialog"
    top="30px"
    :fullscreen="fullVisible"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="80px" class="yt-form yt-dialog-form">
      <el-form-item label="选择考试" prop="examId">
        <el-select placeholder="请选择" v-model="formData.examId" @change="getExamDetail" filterable>
          <el-option v-for="item in examOptions" :key="item.examId" :label="item.name" :value="item.examId"> </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="yt-container yt-container-flex">
      <div class="yt-content-tree has-border">
        <div class="header">
          <YTIcon style="margin: 0 5px 0 0; font-size: 20px" :href="'#icon-fenlei1'" />
          <p>按技能选择</p>
        </div>
        <div class="tree has-tool">
          <el-tree ref="department-tree" :data="abilityData" @node-click="handleNodeClick" node-key="abilityId" :highlight-current="true">
            <div slot-scope="{ node, data }" class="tree-node">
              <el-tooltip effect="dark" :content="data.abilityName" :disabled="data.abilityName.length < 10" placement="top-start">
                <div class="el-tree-node__label">{{ data.abilityName }}</div>
              </el-tooltip>
              <div class="tools">{{ data.questionCount }}</div>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="yt-content has-border">
        <template v-if="questionList.length > 0">
          <div class="question-container">
            <!--题目卡片-->

            <div v-for="(question, qIndex) in questionList" :key="qIndex" class="item" :id="'anchor-' + question.id">
              <div style="width: 100%">
                <!--题目卡片头部-->
                <div class="header">
                  <div class="question-type">
                    {{ ytConstant.questionType.getLabel(question.questionType)[0] }}
                  </div>
                </div>
                <div class="body">
                  <!--题干-->
                  <div class="stem">
                    {{ qIndex + 1 }}.&nbsp;
                    <div class="info" v-html="question.stem"></div>
                  </div>
                  <component :is="questionComponents[question.questionType]" :question="question" style="margin-top: 16px" />
                  <div class="correct-answer">
                    <div style="flex-shrink: 0;letter-spacing: 1px">正确答<span style="letter-spacing: 5px">案:</span></div>
                    <div v-html="question.questionAnswer"></div>
                  </div>
                  <div style="display: flex">
                    <div style="flex-shrink: 0">
                      <span style="letter-spacing: 12px">解</span>
                      <span style="letter-spacing: 5px">析:</span>
                    </div>
                    <div v-html="question.analysis"></div>
                  </div>
                  <div class="tag-and-knowledge" ref="tagAndKnowledge">
                    <div class="tk-item tk-select-item">
                      <span style="letter-spacing: 12px">标</span>
                      <span style="letter-spacing: 5px">签:</span>
                      <template v-for="(tag, tIndex) in question.tags">
                        <div :key="tIndex">
                          <el-tooltip effect="dark" :disabled="tag.name.length < 4" :content="tag.name" placement="top" :open-delay="1000">
                            <div class="tk-point" :key="tIndex" v-if="tIndex < showSize">
                              {{ tag.name }}
                            </div>
                          </el-tooltip>
                        </div>
                      </template>
                      <el-popover
                        placement="top"
                        width="202"
                        trigger="hover"
                        title="题目标签"
                        v-if="question.hasOwnProperty('tags') && question.tags && showSize < question.tags.length"
                      >
                        <div class="tk-select-item tab-list" style="margin-bottom: 5px">
                          <template v-for="(tagPop, tPopIndex) in question.tags">
                            <div :key="tPopIndex">
                              <el-tooltip effect="dark" :disabled="tagPop.name.length < 4" :content="tagPop.name" placement="top" :open-delay="1000">
                                <div class="tk-point" :key="tPopIndex" v-if="tPopIndex >= showSize" style="margin-bottom: 5px">
                                  {{ tagPop.name }}
                                </div>
                              </el-tooltip>
                            </div>
                          </template>
                        </div>
                        <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px" />
                      </el-popover>
                    </div>
                  </div>
                </div>
                <!--题目卡片底部-->
                <div class="footer">
                  <div>
                    <p class="inactive">难度系<span style="letter-spacing: 5px">数:</span>{{ question.difficulty / 10 }}</p>
                    <p slot="reference" class="popover-reference">使用次<span style="letter-spacing: 5px">数:</span>{{ question.useCount || 0 }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="empty" style="height: 100%">
          <template v-if="!questionListLoading" :style="height">
            <YTIcon :href="'#icon-zanwushuju'" style="font-size: 120px" />
          </template>
        </div>
      </div>
    </div>
    <div slot="footer" class="footer" style="justify-content: space-between">
      <el-button type="primary" class="button" @click="fullVisible = !fullVisible">
        <div>
          <YTIcon :href="fullVisible ? '#icon-quanpingshouqi1' : '#icon-quanping'"></YTIcon>
          <p>全屏编辑</p>
        </div>
      </el-button>
      <div>
        <el-button class="button" @click="visible = false">取消</el-button>
        <el-button class="button" type="primary" :loading="loading" @click="submit">确认</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import newExamManageApi from '@api/newExamManage'
import newExerciseExamApi from '@api/newExerciseExam'
import jobApi from '@api/job'
import TrueOrFalse from '@components/manage/question/list/questions/TrueOrFalse'
import Choice from '@components/manage/question/list/questions/Choice'
import Code from '@components/manage/question/list/questions/Code'
export default {
  name: 'ExamInviteDialog',
  components: {
    YTIcon
  },
  data() {
    return {
      visible: false,
      fullVisible: false,
      loading: false,
      formData: {
        examId: '',
        jobResumeIds: []
      },
      ruleValidate: {
        examId: [{ required: true, message: '请选择考试' }]
      },
      examOptions: [], //考试列表
      abilityData: [], //技能树
      questionList: [], //题目列表
      questionListLoading: false,
      showSize: 10, //根据屏幕宽度展示标签的个数
      auditIcons: ['#icon-yishenhe', '#icon-caogao', '#icon-weishenhe'], //审核图标
      questionComponents: [TrueOrFalse, Choice, Choice, null, null, Code] //题目组件
    }
  },
  methods: {
    open(jobResumeIds) {
      this.visible = true
      this.formData.jobResumeIds = this.$deepCopy(jobResumeIds)
      this.getExamList()
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true
          jobApi
            .inviteExam(this.formData)
            .then(res => {
              if (res.res === true) {
                this.$message.success('已邀请考试')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.formData = {
        examId: '',
        jobResumeIds: []
      }
      this.loading = false
    },
    getExamList() {
      //获取考试菜单
      newExamManageApi.getJobExam().then(res => {
        this.examOptions = res.res
      })
    },
    getExamDetail() {
      //获取考试详情
      newExerciseExamApi.getExamAbility(this.formData.examId).then(res => {
        this.abilityData = res.res
        if (this.abilityData.length > 0) {
          this.questionList = this.abilityData[0].questions
        }
      })
    },
    handleNodeClick(data) {
      //树节点点击
      this.questionList = data.questions
    }
  }
}
</script>
<style lang="less" scoped>
.yt-dialog {
  ::v-deep .el-dialog {
    width: 1080px;
    border-radius: 8px !important;
    margin: 0 auto 30px;
  }
  ::v-deep .el-dialog__body {
    padding: 40px 20px 0;
    height: calc(100vh - 225px);
    min-height: 520px;
  }
  .footer {
    .flexStyle(flex);
    margin: 0 20px;
  }
}
.yt-form {
  margin: 0 40px;
  .el-select {
    width: 100%;
    ::v-deep .el-input {
      width: 100%;
    }
  }
}
.yt-container {
  height: calc(100% - 88px);
  padding: 0;
  margin-top: 20px;
  background-color: #fff;
  .has-border {
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    box-shadow: none;
  }
  .yt-content-tree {
    width: 260px;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    box-shadow: none;
    .tree .tools {
      display: block;
    }
    .is-current {
      .tools {
        background-color: #f0f0f0;
      }
    }
    .has-configured {
      width: 48px;
      height: 20px;
      line-height: 20px;
      color: #4ce47a;
      background: #effff4;
      border-radius: 4px;
      text-align: center;
      font-size: @small;
    }
  }
  .yt-content {
    padding: 0;
    overflow-y: auto;
    .yt-header-toolbar {
      margin: 12px 20px;
      justify-content: space-between;
    }
    svg {
      font-size: @default;
      fill: #999999;
    }
  }
}
.question-container {
  height: 100%;
  overflow-y: auto;
  .item {
    display: flex;
    background-color: #ffffff;
    margin-bottom: 9px;
    .checkbox {
      height: 16px;
      margin-top: 19px;
      margin-right: 4px;
      margin-left: 20px;
      ::v-deep .el-checkbox__label {
        display: none;
      }
    }
    .header,
    .body,
    .footer {
      padding-left: 20px;
    }
    .header {
      .flexStyle(flex, flex-start);
      margin: 16px 0 6px;
      .question-type {
        width: 23px;
        height: 23px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        font-weight: bold;
        background: #448bff;
        border-radius: 50%;
        margin-left: 4px;
        margin-right: 16px;
        font-size: 14px;
      }
      .audit {
        .flexStyle(flex, center, center);
        height: 23px;
        overflow: hidden;
      }
      svg {
        font-size: 68px;
      }
    }
    //题目部分
    .body {
      font-size: @small;
      width: 97%;
      .stem {
        display: flex;
        .info {
          width: 97%;
          overflow-wrap: break-word;
        }
      }
      .stem-code {
        height: 200px;
        margin-right: 40px;
        margin-top: 15px;
      }
      .correct-answer {
        display: flex;
        color: #06c281;
        margin: 16px 0;
      }
      .tag-and-knowledge {
        .flexStyle(flex, flex-start, center);
        margin: 14px 0 0;
        padding-bottom: 15px;
        border-bottom: 1px dotted #e9e9e9;
        height: 20px;
        .tk-item {
          .flexStyle(flex, flex-start, center);
          width: calc(100% / 2);
        }
      }
    }
    .footer {
      .flexStyle(flex, space-between, center);
      height: 20px;
      margin: 10px 0;
      font-size: @small;
      padding-right: 30px;
      div {
        .flexStyle(flex, flex-start, center);
      }
      .el-input {
        max-width: 60px;
        ::v-deep .el-input__inner {
          height: 20px;
          border: 1px solid #d9d9d9;
        }
      }
      p {
        height: 18px;
        line-height: 20px;
        border-right: 1px solid #d8d8d8;
        padding-right: 18px;
        margin-right: 16px;
        cursor: pointer;
        &:last-child {
          height: 18px;
          border-right: 0px solid #d8d8d8;
          line-height: 20px;
          padding-right: 18px;
          margin-right: 16px;
          cursor: pointer;
        }
      }
      .inactive {
        cursor: initial;
        &:hover {
          color: #000;
        }
      }
      svg {
        color: #999999;
        &:hover {
          color: #448bff;
        }
      }
      .button {
        color: #666666;
        margin-left: 17px;
        cursor: pointer;
        &:hover {
          color: #448bff;
          svg {
            color: #448bff;
          }
        }
        svg {
          font-size: 14px;
          margin-right: 6px;
        }
      }
    }
  }
}
.empty {
  .flexStyle(flex, center, center);
  background-color: #ffffff;
  svg {
    font-size: 100px;
  }
}
//题库列表悬停标签
.tab-list {
  display: flex;
  flex-wrap: wrap;
}
.tk-point {
  max-width: 70px;
  height: 22px;
  line-height: 22px;
  border-color: #edf4ff;
  border-radius: 2px;
  color: #448bff;
  margin-right: 8px;
  padding: 0 8px;
  background-color: #edf4ff;
  font-size: @small;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  i {
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
  }
  &:focus {
    border-color: #448bff;
  }
}
</style>
